
import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Carousel, CarouselItem } from "react-bootstrap"
import banner from "../images/banner.png"
import rolle_ausgerollt from "../images/rolle/product_image.png"
import Seo from "../components/seo"


const IndexPage = ({data}) => {
  return (
    <Layout>
      <Seo />
      <section>
        <div class="carousel relative container mx-auto">
          <div class="carousel-inner relative overflow-hidden w-full">
            <img src={banner} alt="Produktbild Pimmelband" />
          </div>
        </div>
      </section>

      <section class="bg-white py-8">
        <div class="container mx-auto flex items-center flex-wrap pt-4 pb-12">
          <div class="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 px-2 py-3">
            <h2 class="uppercase tracking-wide no-underline hover:no-underline hover:text-gray-800 font-bold text-gray-800 text-xl ">
              Store
            </h2>
            <div class="flex items-center" id="store-nav-content">
              <h3 class="pl-3 inline-block no-underline hover:text-black">
                 {/* Icon Sort Up */}
              </h3>
            </div>
          </div>            
          
          <div class="w-full md:w-1/3 xl:w-1/3 px-4 pb-12">
            {data.slideShow.edges.map(({node}) => (
                <GatsbyImage class="w-full" image={node.childImageSharp.gatsbyImageData} alt={node.base} />
            ))}
            

            <div class="pt-3 flex items-center justify-between">
              <p class="">Pimmelband Klebeband</p>
              {/* Icon Sort Down */}
            </div>
            <p class="pt-1 text-gray-900">€10,00 zzgl. Versand</p>

            <button 
              class="w-full snipcart-add-item bg-transparent hover:bg-pink-500 text-pink-700 font-semibold hover:text-gray-800 py-2 px-4 border border-pink-500 hover:border-transparent rounded"
              data-item-id="pimmelband"
              data-item-price="10.00"
              data-item-url="https://www.pimmelband.de"
              data-item-name="Pimmelband"
              data-item-image={rolle_ausgerollt}
            >
              Zum Warenkorb hinzufügen
            </button> 
          </div>

          <div class="w-full md:w-2/3 xl:w-2/3 px-4">
            <p class="font-semibold">Produktbeschreibung:</p>
            <p>Mit dem Pimmelband ist Spaß und Gelächter auf dem Junggesellenabschied oder anderen Partys garantiert. Perfekt geeignet zum sicheren Verschicken von Dokumenten oder Paketen. </p>
            <p class="font-semibold">Lieferzeit:</p>
            <p>Die Lieferzeit beträgt max. 2 Wochen nach Zahlungseingang.</p>
          </div>
        </div>
      </section>

      <section>
        <div class="carousel relative container mx-auto">
          <div class="carousel-inner relative overflow-hidden w-full">
            <img src={banner} alt="Band" />
          </div>
        </div>
      </section>
    </Layout>
  )
}


export default IndexPage

export const pageQuery = graphql`
  query {
    slideShow: allFile(
      sort: {fields: base, order: ASC}
      filter: {relativeDirectory: {eq: "rolle"}}
    ) {
      edges {
        node {
          id
          relativePath
          base
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              quality: 70
              transformOptions: {fit: CONTAIN}
              height: 300 
              width: 225
            )
          }
        }
      }
    }
  }
`
